<template>
  <button
    v-scroll-to="{ el: 'body', cancelable: false }"
    type="button"
    class="group align-center sm:hidden fixed right-0 z-40 bg-gray-200 text-lg leading-none p-3 mr-4 mb-4 rounded-full"
    :style="`bottom: ${buttonOffset}px`"
  >
    <span class="pl-2 hidden align-middle group-hover:inline">Back to top</span>
    <Icon name="IconArrowUp" class="text-2xl" />
  </button>
</template>

<script setup lang="ts">
import { mapState } from 'pinia'
import { useUiStore } from '~/stores/ui'

const uiStore = useUiStore()
const buttonOffset = computed(() => uiStore.getFooterOffset)
</script>
